import React from 'react'
import './HeroStyles.css'

const Hero = () => {
  return (
    <div className='hero'>
        <div className='container'>
            <div className='content'>
                <h2>Data</h2>
                <h2 className='blue'>Secured</h2>
                <h2>Protection</h2>
                <div><button>Learn More</button></div>
            </div>
        </div>
    </div>
  )
}

export default Hero